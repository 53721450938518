import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { nanoid } from 'nanoid';
import { Item, Input, Select, Option, Label, Hint } from 'components';
import ITemplate from 'lib/Intefaces/ITemplate';

import DetailedSI from './DetailedSI/DetailedSI';
import { AddStorageInformationButton } from './styled';

const getEmptyStorageInfo = () => ({
  id: nanoid(),
  newStorage: true,
  identifier: '',
  type: 'Cookie',
  name: '',
  maxAgeSeconds: '',
  domain: '',
  description: '',
});

type StorageInformationProps = {
  selectedLanguage: string;
  existingLanguages?: Array<string>;
  indexOfSelectedLanguage?: number;
  editTemplate?: boolean;
  isReadAccess?: boolean;
  form: FormInstance<any>;
  setTemplates?: Dispatch<SetStateAction<ITemplate[]>>;
};

const StorageInformation = ({
  selectedLanguage,
  editTemplate,
  existingLanguages,
  isReadAccess = false,
  form,
  setTemplates,
}: StorageInformationProps) => {
  const [translationId, setTranslationId] = useState<number>(0);

  const addStorageInformationToOtherLanguages = () => {
    if (setTemplates) {
      setTemplates((templates) =>
        templates.map((template) => {
          if (template.language === selectedLanguage) {
            return template;
          }

          template.deviceStorage.disclosures.push({
            id: 0,
            newStorage: true,
            identifier: '',
            type: 'Cookie',
            name: '',
            maxAgeSeconds: '',
            domain: '',
            description: '',
          });

          return template;
        }),
      );
    }
  };

  const removeStorageInformationByIndex = (index: number) => {
    if (setTemplates) {
      setTemplates((templates) =>
        templates.map((template) => {
          if (template.language === selectedLanguage) {
            return template;
          }

          template.deviceStorage.disclosures.splice(index, 1);

          return template;
        }),
      );
    }
  };

  const saveStorageInformationByIndex = (index: number) => {
    if (setTemplates) {
      setTemplates((templates) =>
        templates.map((template) => {
          if (template.language === selectedLanguage) {
            return template;
          }

          const disclosure = template.deviceStorage.disclosures.at(index);
          if (disclosure) {
            disclosure.newStorage = false;
          }

          return template;
        }),
      );
    }
  };

  return (
    <>
      <Item
        label="Maximum Duration of Cookie Storage"
        colon={false}
        name="cookieMaxAgeSeconds"
        tooltip="The longest potential duration for cookie storage on a user’s device. In case the service uses multiple cookies with differing durations, this value represents the cookie with the longest duration. Example: “2 years” or “10 seconds”"
      >
        <Input placeholder="Enter the maximum duration of cookie storage" disabled={isReadAccess} />
      </Item>
      <Item
        label="Non-Cookie Storage"
        colon={false}
        name="usesNonCookieAccess"
        tooltip="This field indicates whether the service uses non-cookie methods to store or access information on a user’s device."
      >
        <Select allowClear disabled={isReadAccess} placeholder="Plesae select">
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Item>
      <Item
        label="Device Storage Disclosure URL"
        colon={false}
        name="deviceStorageDisclosureUrl"
        tooltip="Location of .json file hosted by the service which discloses additional storage information"
      >
        <Input placeholder="Enter Device Storage Disclosure URL" disabled={isReadAccess} />
      </Item>
      <Label>
        Detailed Storage Information
        <Hint title="Individual storage information details." />
      </Label>
      <Form.List name={['deviceStorage', 'disclosures']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <DetailedSI
                key={field.key}
                field={field}
                index={translationId}
                onDelete={() => {
                  remove(field.name);
                  removeStorageInformationByIndex(index);
                }}
                onSave={() => saveStorageInformationByIndex(index)}
                editTemplate={editTemplate}
                selectedLanguage={selectedLanguage}
                existingLanguages={existingLanguages}
                isReadAccess={isReadAccess}
                onTranslation={() => setTranslationId(index)}
                form={form}
                setTemplates={setTemplates}
              />
            ))}
            {!isReadAccess && (
              <AddStorageInformationButton
                onClick={() => {
                  add(getEmptyStorageInfo());
                  addStorageInformationToOtherLanguages();
                }}
              >
                <span>+</span>Add Storage Information
              </AddStorageInformationButton>
            )}
          </>
        )}
      </Form.List>
    </>
  );
};

export default StorageInformation;
