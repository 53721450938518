import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormInstance, FormListFieldData } from 'antd';
import { useLazyQuery } from '@apollo/client';
import ITemplate from 'lib/Intefaces/ITemplate';
import { Item, Input, Select, Option, Flex, Button, AutotranslateButton, TextArea } from 'components';

import GET_TRANSLATIONS from 'lib/gql/queries/getTranslations';

import { Container, StorageInfoButton } from './styled';

type DetaildSIProps = {
  onDelete: () => void;
  onSave: () => void;
  editTemplate?: boolean;
  selectedLanguage: string;
  existingLanguages?: Array<string>;
  setValues?: any;
  index: number;
  isReadAccess?: boolean;
  onTranslation: () => void;
  field: FormListFieldData;
  form: FormInstance<any>;
  setTemplates?: Dispatch<SetStateAction<ITemplate[]>>;
};

const DetaildSI = ({
  onDelete,
  onSave,
  editTemplate,
  selectedLanguage,
  existingLanguages,
  index,
  isReadAccess = false,
  onTranslation,
  field,
  form,
  setTemplates,
}: DetaildSIProps) => {
  const [translating, setTranslating] = useState(false);
  const [translated, switchTranslated] = useState(false);
  const [collapsed, collapseInformation] = useState(false);
  const [loadData] = useLazyQuery(GET_TRANSLATIONS, {
    onCompleted: (data) => {
      const translations = data.getTranslation;
      if (setTemplates) {
        setTemplates((ts) =>
          ts.reduce(
            (acc: Array<ITemplate>, curr: ITemplate) => [
              ...acc,
              {
                ...curr,
                deviceStorage: {
                  disclosures: curr.deviceStorage.disclosures.map((storage, storageIndex) =>
                    storageIndex === index ? { ...storage, description: translations[curr.language] } : storage,
                  ),
                },
              },
            ],
            [],
          ),
        );
      }
      setTranslating(false);
      switchTranslated(true);
    },
  });

  const autotranslate = (value: string) => {
    setTranslating(true);
    loadData({
      variables: {
        translationFor: value,
        sourceLanguage: 'en',
        targetLanguages: existingLanguages,
      },
    });
  };

  const storageInformation = form.getFieldValue(['deviceStorage', 'disclosures', field.name]);

  return (
    <Container>
      <StorageInfoButton
        onClick={() => !storageInformation.newStorage && collapseInformation(!collapsed)}
        type="button"
        style={{ marginBottom: collapsed ? 0 : '16px' }}
      >
        {storageInformation.newStorage ? 'Add Storage Information' : storageInformation.name}
      </StorageInfoButton>
      {!collapsed && (
        <>
          <Flex justifyContent="space-between">
            <Item
              label="Name"
              tooltip="Name assigned to the stored information"
              name={[field.name, 'name']}
              colon={false}
              style={{ width: 'calc(50% - 12px)' }}
            >
              <Input disabled={isReadAccess} placeholder="Enter name" />
            </Item>
            <Item
              label="Identifier"
              tooltip="Key or object name, depending on type, for the storage item"
              name={[field.name, 'identifier']}
              colon={false}
              style={{ width: 'calc(50% - 12px)' }}
            >
              <Input disabled={isReadAccess} placeholder="Enter identifier" />
            </Item>
          </Flex>
          <Flex justifyContent="space-between">
            <Item
              label="Type"
              tooltip="Type of storage used. 'cookie', 'web', ‘app’. Note “web” can represent local storage and indexDB."
              name={[field.name, 'type']}
              colon={false}
              style={{ width: 'calc(50% - 12px)' }}
            >
              <Select disabled={isReadAccess} placeholder="Select type">
                <Option key="Cookie" value="Cookie">
                  Cookie
                </Option>
                <Option key="App" value="App">
                  App
                </Option>
                <Option key="Web" value="Web">
                  Web
                </Option>
              </Select>
            </Item>
            <Item
              label="Duration"
              tooltip="The time period that the information is stored on the user's device. Only required for the type = ‘cookie’; otherwise null. Example: “2 years” or “10 seconds”"
              name={[field.name, 'maxAgeSeconds']}
              colon={false}
              style={{ width: 'calc(50% - 12px)' }}
            >
              <Input disabled={isReadAccess} placeholder="Enter duration" />
            </Item>
          </Flex>
          <Item
            label="Domain"
            tooltip="The domain that is related to the stored information. Only required if type='cookie' or type='web'"
            name={[field.name, 'domain']}
            colon={false}
          >
            <Input disabled={isReadAccess} placeholder="Enter domain" />
          </Item>
          <Item
            label="Description"
            tooltip="Description of the stored information."
            name={[field.name, 'description']}
            colon={false}
          >
            <TextArea
              placeholder="Description"
              style={{ minHeight: '60px', maxHeight: '100px' }}
              disabled={isReadAccess}
            />
          </Item>
          {selectedLanguage === 'en' && editTemplate && !isReadAccess && (
            <AutotranslateButton
              translating={translating}
              translated={translated}
              onClick={() => {
                onTranslation();
                autotranslate(storageInformation.description);
              }}
            />
          )}
          {!isReadAccess && (
            <Flex justifyContent="flex-end">
              {storageInformation.newStorage ? (
                <>
                  <Button styleType="secondary" size="small" onClick={onDelete} style={{ marginRight: '12px' }}>
                    Cancel
                  </Button>
                  <Button
                    styleType="primary"
                    size="small"
                    onClick={() => {
                      form.setFieldValue(['deviceStorage', 'disclosures', field.name, 'newStorage'], false);
                      collapseInformation(true);
                      onSave();
                    }}
                  >
                    Add
                  </Button>
                </>
              ) : (
                <Button styleType="red" size="small" onClick={onDelete}>
                  Delete
                </Button>
              )}
            </Flex>
          )}
        </>
      )}
    </Container>
  );
};

export default DetaildSI;
